// --------------------------------------------------
// Imports
// --------------------------------------------------

import "flowbite";
import { SmoothPageScroll } from "./js/SmoothPageScroll";
import { ClipPathSlider } from "./js/ClipPathSlider";
import { FadeSlider } from "./js/FadeSlider";
import { SlideReveal } from "./js/SlideReveal";
import { VideoController } from "./js/VideoController";
import { ParallaxImages } from "./js/ParallaxImages";
import { Preload } from "./js/preload";

// --------------------------------------------------
// onBeforeUnload
// --------------------------------------------------

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

// --------------------------------------------------
// onLoad
// --------------------------------------------------

window.addEventListener("load", (e) => {
  new Preload();
  new SmoothPageScroll();
  document.querySelectorAll(".js-slider").forEach((el) => new ClipPathSlider(el));
  document.querySelectorAll("[data-fade-slider]").forEach((el) => new FadeSlider(el));
  document.querySelectorAll("[data-slide-reveal]").forEach((el) => new SlideReveal(el));
  document.querySelectorAll("[data-img-target]").forEach((el, i) => new ParallaxImages(el));
  document.querySelectorAll("[data-video]").forEach((el, i) => new VideoController(el));
});
