import { gsap } from "gsap";

export class FadeSlider {
  constructor(el) {
    this.slideContainer = el;
    this.slides = gsap.utils.toArray(
      "[data-fade-slider-target]",
      this.slideContainer
    );
    this.slideLength = this.slides.length;
    this.getElement = gsap.utils.wrap(this.slides);
    this.currentIndex = 0;
    this.nextIndex = this.currentIndex + 1;
    this.isTweening = false;
    this.currentImage = null;
    this.nextImage = null;
    this.timer = gsap.delayedCall(3, () => this.doSlide(1));
    this.init();
  }

  init() {
    this.slides.forEach((el, i) => {
      if (i > 0) {
        gsap.set(el, { opacity: 0 });
      } else {
        gsap.to(el.querySelector("img"), {
          ease: "circ.out",
          duration: 8,
          transformOrigin: "center center",
          willChange: "opacity, transform",
          scale: 1.2,
        });
      }
    });
  }

  doSlide = (direction) => {
    if (this.isTweening) return;
    this.isTweening = true;

    this.timer.pause();

    this.currentImage = this.getElement(this.currentIndex);
    this.nextIndex =
      (this.currentIndex + direction + this.slideLength) % this.slideLength;
    this.nextImage = this.getElement(this.nextIndex);

    // Stop the slide if the next index is the same
    if (this.nextIndex === this.currentIndex) {
      this.isTweening = false;
      this.timer.restart(true);
      return;
    }

    gsap.to(this.currentImage, { duration: 0.5, opacity: 0 });

    gsap.fromTo(
      this.nextImage,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
          this.currentIndex = this.nextIndex;
          this.isTweening = false;
          this.timer.restart(true);
        },
      }
    );
    gsap.fromTo(
      this.nextImage.querySelector("img"),
      { scale: 1 },
      {
        duration: 8,
        ease: "power3.out",
        overwrite: "auto",
        transformOrigin: "center center",
        willChange: "opacity, transform",
        scale: 1.2,
      }
    );
  };
}
