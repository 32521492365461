import { gsap } from "gsap";

export class ClipPathSlider {
  constructor(el) {
    this.slides = gsap.utils.toArray(".js-slider__slide", el);
    this.slideButtonContainer = el.querySelector(".js-slider__buttons");
    this.slideButtonsInner = el.querySelectorAll(".js-slider__innerButtons");
    this.slideButtons = null;
    this.slideLength = this.slides.length;
    this.getElement = gsap.utils.wrap(this.slides);
    this.slideTimer = el.querySelector(".js-slider__timer");
    this.currentIndex = 0;
    this.nextIndex = 1;
    this.isTweening = false;
    this.currentImage = null;
    this.nextImage = null;
    this.defaults = { duration: 0.6, ease: "circ.inOut", overwrite: "auto" };
    this.timer = gsap.delayedCall(5, () => this.doSlide(1));
    this.init();
  }

  init() {
    // hide all non active slides
    this.slides.forEach((el, i) => {
      if (i > 0) {
        gsap.set(el, { clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)" });
        gsap.set(el.querySelector(".js-slider__text "), { opacity: 0, y: 50 });
      } else {
        gsap.set(el, {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        });
      }
    });

    // build buttons
    for (let i = 0; i < this.slideLength; i++) {
      const button = document.createElement("button");
      button.classList =
        "js-slider__button flex flex-grow w-44 h-[4px] bg-white/50";
      button.innerHTML = `<span class="js-slider__buttonBar h-[4px] origin-left w-full scale-x-0 bg-white"></span><span class="sr-only">Slide ${i}</span>`;
      button.addEventListener("click", () => {
        this.doSlide(i - this.currentIndex);
      });
      this.slideButtonContainer.appendChild(button);
    }
    this.slideButtonsInner.forEach((el) => {
      for (let i = 0; i < this.slideLength; i++) {
        const button = document.createElement("button");
        button.classList = "h-10 w-10 bg-white rounded-full";
        let img;
        switch (i) {
          case 0:
            img = "visage";
            break;
          case 1:
            img = "mirage";
            break;
          default:
            img = "eclipse";
            break;
        }
        button.style = `background-image:url('./assets/img/${img}-model-material.jpg');`;
        button.addEventListener("click", () => {
          this.doSlide(i - this.currentIndex);
        });
        el.appendChild(button);
      }
    });
    // default
    this.slideButtons = document.querySelectorAll(".js-slider__button");
    this.timeElapsed(
      this.slideButtons[0].querySelector(".js-slider__buttonBar")
    );
  }

  resetTimer() {
    this.isTweening = false;
    this.timer.restart(true);
    gsap.set(".js-slider__buttonBar", { scaleX: "0%" });
    this.timeElapsed(
      this.slideButtons[this.currentIndex].querySelector(
        ".js-slider__buttonBar"
      )
    );
  }

  timeElapsed(el) {
    gsap.killTweensOf(".js-slider__buttonBar");
    gsap.to(el, { duration: 5, ease: "none", scaleX: "100%" });
  }

  doSlide = (direction) => {
    if (this.isTweening) return;
    this.isTweening = true;

    this.timer.pause();

    this.currentImage = this.getElement(this.currentIndex);
    this.nextIndex =
      (this.currentIndex + direction + this.slideLength) % this.slideLength;
    this.nextImage = this.getElement(this.nextIndex);

    // Stop the slide if the next index is the same
    if (this.nextIndex === this.currentIndex) {
      this.resetTimer();
      return;
    }

    // anim
    gsap.to(this.currentImage, {
      ...this.defaults,
      clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
    });
    gsap.to(this.currentImage.querySelector(".js-slider__text "), {
      duration: 1,
      opacity: 0,
      y: 50,
    });

    gsap.fromTo(
      this.nextImage,
      {
        clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
      },
      {
        ...this.defaults,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        onComplete: () => {
          this.currentIndex = this.nextIndex;
          this.resetTimer();
        },
      }
    );

    gsap.fromTo(
      this.nextImage.querySelector("img"),
      { scale: 1 },
      {
        duration: 8,
        ease: "power3.out",
        overwrite: "auto",
        transformOrigin: "center center",
        willChange: "opacity, transform",
        scale: 1.2,
      }
    );

    gsap.to(this.nextImage.querySelector(".js-slider__text "), {
      duration: 1,
      opacity: 1,
      y: 0,
    });
  };
}
