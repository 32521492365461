import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

gsap.registerPlugin(CSSRulePlugin);

export class Preload {
  constructor() {
    this.bg = CSSRulePlugin.getRule(".js .loading::before");
    this.dibber = CSSRulePlugin.getRule(".js .loading::after");
    this.init();
  }
  init() {
    let tl = gsap.timeline({
      onComplete: () => document.body.classList.remove("loading"),
    });
    tl.to(this.dibber, {
      cssRule: { opacity: 0 },
      ease: "power3",
      duration: 1,
    });
    tl.fromTo(
      this.bg,
      { height: "100%" },
      { cssRule: { height: "0%" }, duration: 0.8 },
      "<"
    );
  }
}
