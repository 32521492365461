import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class VideoController {
  constructor(el) {
    this.videoContainer = el;
    this.video = this.videoContainer.querySelector("video");
    this.init();
  }
  init() {
    ScrollTrigger.create({
      trigger: this.videoContainer,
      start: "top bottom",
      end: "bottom top+=15%",
      scrub: true,
      onEnter: () => this.video.play(),
      onLeave: () => this.video.pause(),
      onEnterBack: () => this.video.play(),
      onLeaveBack: () => this.video.pause(),
    });
  }
}
