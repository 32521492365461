import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export class ParallaxImages {
  constructor(el) {
    this.elem = el;
    this.init();
  }
  init() {
    gsap.to(this.elem, {
      yPercent: -20,
      ease: "none",
      scrollTrigger: {
        trigger: this.elem,
        start: "top bottom-=15%", // the default values
        end: "bottom top",
        scrub: true,
      },
    });
  }
}
